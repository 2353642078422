<template>
  <div class="form-container">
    <ValidationObserver ref="form">
      <!-- 투자내용 입력 폼 -->
      <form>
        <fieldset>
          <legend>{{$t('content.accelerating.academy.Common.txt05')}}</legend>
          <div class="align-right">
            <!-- *는 필수입력 항목입니다. -->
            <em class="required-mark">*</em>{{ $t('content.networking.CompanyInfo.txt53') }}
          </div>
          <ul>
            <!-- (AC114) Corporate ACC-Hyundai인 경우에만 : 투자이력 없음 표시 -->
            <li
              v-if="accApplyPgmMngDetail.accKind === 'AC114'"
              class="form-row">
              <BaseCheckbox
                id="invest_history_check"
                v-model="accInfo.invest_history_check"
                trueValue="1"
                falseValue="0"
                >{{ $t('content.accelerating.academy.InvestmentInfo.txt08') }}</BaseCheckbox>

              <!-- (AC114) Corporate ACC-Hyundai인 경우에만 : 최소 지원 자격은 기존 투자 유무와 관계없이 지원 시점에서 법인 설립이 완료된 기업입니다. -->
              <span
                class="input-desc"
              >{{$t('content.accelerating.academy.InvestmentInfo.txt07')}}</span>

            </li>

            <!-- 투자유치 현황 -->
            <!-- * 투자입력 없음인 경우에는 : 투자유치 현황 표시하지 않음 -->
            <!-- * (AC114) Corporate ACC-Hyundai인 경우에는 : 투자유치 현황 필수입력 -->
            <li
              v-if="(accApplyPgmMngDetail.investDateYn || accApplyPgmMngDetail.investOrgYn ||
                accApplyPgmMngDetail.investAmountYn || accApplyPgmMngDetail.investRoundYn) &&
                accInfo.invest_history_check === '0'"
              class="form-row">
              <BaseLabelElement
                :label="$t('content.accelerating.academy.InvestmentInfo.txt01')"
                :required="accApplyPgmMngDetail.accKind === 'AC114'"
              />

              <!-- 투자일자 -->
              <ValidationProvider
                v-if="accApplyPgmMngDetail.investDateYn"
                vid="01_invest_001_invest_date"
                :name="$t('content.accelerating.academy.InvestmentInfo.txt02')"
                :rules="{ required: accApplyPgmMngDetail.accKind === 'AC114' }"
                v-slot="{ failed }"
                slim
              >
                <TypeableDatepicker
                  ref="01_invest_001_invest_date"
                  v-model="accInvestInfo[0].invest_date"
                  width="half"
                  id="invest_date"
                  :placeholder="$t('content.accelerating.academy.InvestmentInfo.txt02') + ' (YYYY-MM-DD)'"
                  initialView="year"
                  :invalid="failed"
                />
              </ValidationProvider>

              <!-- 투자기관 -->
              <ValidationProvider
                v-if="accApplyPgmMngDetail.investOrgYn"
                vid="01_invest_002_invest_org"
                :name="$t('content.accelerating.academy.InvestmentInfo.txt03')"
                :rules="{ required: accApplyPgmMngDetail.accKind === 'AC114' }"
                v-slot="{ failed }"
                slim
              >
                <BaseInputElement
                  ref="01_invest_002_invest_org"
                  v-model="accInvestInfo[0].invest_org"
                  width="half"
                  id="invest_org"
                  :placeholder="$t('content.accelerating.academy.InvestmentInfo.txt03')"
                  :invalid="failed"
                />
              </ValidationProvider>

              <!-- 투자금액 -->
              <ValidationProvider
                v-if="accApplyPgmMngDetail.investAmountYn"
                vid="01_invest_003_invest_amount"
                :name="$t('content.accelerating.academy.InvestmentInfo.txt04')"
                :rules="{ required: accApplyPgmMngDetail.accKind === 'AC114' }"
                v-slot="{ failed }"
                slim
              >
                <BaseInputElement
                  ref="01_invest_003_invest_amount"
                  type="number"
                  v-model="accInvestInfo[0].invest_amount"
                  width="half"
                  id="invest_amount"
                  :placeholder="$t('content.accelerating.academy.InvestmentInfo.txt04')"
                  :invalid="failed"
                />
              </ValidationProvider>

              <!-- 라운드 -->
              <ValidationProvider
                v-if="accApplyPgmMngDetail.investRoundYn"
                vid="01_invest_004_invest_round"
                :name="$t('content.accelerating.academy.InvestmentInfo.txt05')"
                :rules="{ required: accApplyPgmMngDetail.accKind === 'AC114' }"
                v-slot="{ failed }"
                slim
              >
                <BaseInputElement
                  ref="01_invest_004_invest_round"
                  v-model="accInvestInfo[0].invest_round"
                  width="half"
                  id="invest_round"
                  :placeholder="$t('content.accelerating.academy.InvestmentInfo.txt05')"
                  :invalid="failed"
                />
              </ValidationProvider>

              <!-- 최근 유치현황부터 입력해 주세요. -->
              <span class="input-desc">
                {{ $t('content.accelerating.academy.InvestmentInfo.txt06') }}
              </span>
              <span class="input-error-desc">{{ investFormErrorMessage(0) }}</span>

              <!-- 투자유치 현황 - 추가 입력 정보 -->
              <!-- * (AC114) Corporate ACC-Hyundai인 경우에는 : 투자유치 현황 필수입력 -->
              <ul
                v-if="accInvestInfo.length > 1"
                class="additional-info-wrap">
                <template
                  v-for="(additionalInvestInfo, index) in accInvestInfo"
                >
                  <li
                    v-if="index > 0"
                    :key="index"
                    class="additional-info">
                    <BaseLabelElement
                      :label="$t('content.accelerating.academy.InvestmentInfo.txt01')"
                      class="input-title"
                      :required="accApplyPgmMngDetail.accKind === 'AC114'"
                    />

                    <!-- 투자일자 - 추가 입력 정보 -->
                    <ValidationProvider
                      v-if="accApplyPgmMngDetail.investDateYn"
                      :vid="'01_invest_' + index + '01_invest_date'"
                      :name="$t('content.accelerating.academy.InvestmentInfo.txt02')"
                      :rules="{ required: accApplyPgmMngDetail.accKind === 'AC114' }"
                      v-slot="{ failed }"
                      slim
                    >
                      <TypeableDatepicker
                        :ref="'01_invest_' + index + '01_invest_date'"
                        v-model="additionalInvestInfo.invest_date"
                        width="half"
                        :id="'invest_date' + index"
                        :placeholder="$t('content.accelerating.academy.InvestmentInfo.txt02') + ' (YYYY-MM-DD)'"
                        initialView="year"
                        :invalid="failed"
                      />
                    </ValidationProvider>

                    <!-- 투자기관 - 추가 입력 정보 -->
                    <ValidationProvider
                      v-if="accApplyPgmMngDetail.investOrgYn"
                      :vid="'01_invest_' + index + '02_invest_org'"
                      :name="$t('content.accelerating.academy.InvestmentInfo.txt03')"
                      :rules="{ required: accApplyPgmMngDetail.accKind === 'AC114' }"
                      v-slot="{ failed }"
                      slim
                    >
                      <BaseInputElement
                        :ref="'01_invest_' + index + '02_invest_org'"
                        v-model="additionalInvestInfo.invest_org"
                        width="half"
                        :id="'invest_org' + index"
                        :placeholder="$t('content.accelerating.academy.InvestmentInfo.txt03')"
                        :invalid="failed"
                      />
                    </ValidationProvider>

                    <!-- 투자금액 - 추가 입력 정보 -->
                    <ValidationProvider
                      v-if="accApplyPgmMngDetail.investAmountYn"
                      :vid="'01_invest_' + index + '03_invest_amount'"
                      :name="$t('content.accelerating.academy.InvestmentInfo.txt04')"
                      :rules="{ required: accApplyPgmMngDetail.accKind === 'AC114' }"
                      v-slot="{ failed }"
                      slim
                    >
                      <BaseInputElement
                        :ref="'01_invest_' + index + '03_invest_amount'"
                        v-model="additionalInvestInfo.invest_amount"
                        width="half"
                        :id="'invest_amount' + index"
                        :placeholder="$t('content.accelerating.academy.InvestmentInfo.txt04')"
                        :invalid="failed"
                      />
                    </ValidationProvider>

                    <!-- 라운드 - 추가 입력 정보 -->
                    <ValidationProvider
                      v-if="accApplyPgmMngDetail.investRoundYn"
                      :vid="'01_invest_' + index + '04_invest_round'"
                      :name="$t('content.accelerating.academy.InvestmentInfo.txt05')"
                      :rules="{ required: accApplyPgmMngDetail.accKind === 'AC114' }"
                      v-slot="{ failed }"
                      slim
                    >
                      <BaseInputElement
                        :ref="'01_invest_' + index + '04_invest_round'"
                        v-model="additionalInvestInfo.invest_round"
                        width="half"
                        :id="'invest_round' + index"
                        :placeholder="$t('content.accelerating.academy.InvestmentInfo.txt05')"
                        :invalid="failed"
                      />
                    </ValidationProvider>

                    <span class="input-error-desc">{{ investFormErrorMessage(index) }}</span>

                    <!-- 삭제 버튼 -->
                    <BaseButton
                      type="line"
                      size="small"
                      @click="deleteInvestInfo(index)"
                    >{{$t('content.common.button.btn07')}}</BaseButton>
                  </li>
                </template>
              </ul>

              <div
                v-if="accInvestInfo.length < LIMIT_INVEST_INFO"
                class="btn-wrap">
                <!-- 추가 버튼 -->
                <BaseButton
                  type="line"
                  size="medium"
                  @click="addInvestInfo"
                >{{$t('content.common.button.btn18')}}</BaseButton>
              </div>
              <!-- // 투자유치 현황 - 추가 입력 정보 -->
            </li>
            <!-- // 투자유치 현황 -->
          </ul>
        </fieldset>

        <!-- 버튼 -->
        <div class="btn-wrap type2">
          <BackButton
            @click="$emit('back')"
          />
          <BaseButton
            type="line"
            @click="$emit('save')"
          >{{$t('content.common.button.btn01')}}</BaseButton>
          <BaseButton
            type="line"
            @click="doPrev"
          >{{$t('content.common.button.btn03')}}</BaseButton>
          <BaseButton
            @click="doNext"
          >{{$t('content.common.button.btn02')}}</BaseButton>
        </div>
        <!-- // 버튼 -->
      </form>
      <!-- // 투자내용 입력 폼 -->
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseLabelElement from '@/components/base/BaseLabelElement.vue';
import BaseInputElement from '@/components/base/BaseInputElement.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BackButton from '@/components/base/BackButton.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import TypeableDatepicker from '@/components/base/TypeableDatepicker.vue';
import AccInvestInfoDto from '../dto/apply/AccInvestInfoDto';

/**
 * Programs > 공모지원 & 공모지원내역 > 공모 지원 (탭) - 4단계 : 투자내용
 */
export default {
  name: 'ProgramsApplyStep4',
  mixins: [mixinHelperUtils],
  components: {
    ValidationObserver, // https://logaretm.github.io/vee-validate/api/validation-observer.html
    ValidationProvider, // https://logaretm.github.io/vee-validate/api/validation-provider.html
    BaseLabelElement,
    BaseInputElement,
    BaseCheckbox,
    BaseButton,
    BackButton,
    TypeableDatepicker,
  },

  props: {
    /**
     * Acc지원프로그램 관리 상세
     * @type {import('../dto/AccApplyPgmMngDetailDto').default}
     */
    accApplyPgmMngDetail: {
      type: Object,
      required: true,
    },
    /**
     * Acc지원 정보 - 지원정보 (회사정보, 서비스, 기타입력 등)
     * @type {import('../dto/apply/AccInfoDto').default} AccInfoDto
     * @see AccApplyDto
     */
    accInfo: {
      type: Object,
      required: true,
    },
    /**
     * Acc지원 정보 - 투자유치 현황
     * @type {AccInvestInfoDto[]}
     * @see AccApplyDto
     */
    accInvestInfo: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      /**
       * 투자유치 현황 입력 개수 제한
       * @type {number}
       */
      LIMIT_INVEST_INFO: 3,
    };
  },

  /**
   * beforeRouteEnter
   * @see {@link https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards In-Component Guards}
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.accApplyPgmMngDetail.isEmptyStep4()) {
        // 입력할 것이 없으면 스텝 건너뛰기
        if (from && from.name === 'ProgramsApplyStep5') {
          vm.doPrev();
        } else {
          vm.doNext();
        }
      }
    });
  },

  methods: {
    /**
     * 투자유치 현황 - 유효성 검사 에러 메시지
     * @param {number} index
     */
    investFormErrorMessage(index) {
      if (this.$refs.form) {
        /**
         * 투자유치 현황 - vid, ref 부여
         * 01_invest_001_invest_date, 01_invest_002_invest_org, 01_invest_003_invest_amount, ...
         * 01_invest_101_invest_date, 01_invest_102_invest_org, 01_invest_103_invest_amount, ...
         * 01_invest_201_invest_date, 01_invest_202_invest_org, 01_invest_203_invest_amount, ...
         */
        const firstErrorVid = Object.keys(this.$refs.form.errors)
          .find((key) => key.startsWith(`01_invest_${index}`) && this.$refs.form.errors[key].length > 0);
        if (firstErrorVid) {
          return this.$refs.form.errors[firstErrorVid].join(', ');
        }
      }
      return '';
    },

    doNext() {
      this.validateForm(this.$refs.form) // mixinHelperUtils
        .then(() => {
          this.$router.push({
            name: 'ProgramsApplyStep5',
          });
        })
        .catch(() => {
          // https://github.com/logaretm/vee-validate/issues/2781 이슈로
          // investFormErrorMessage 표시를 위해서 강제 업데이트
          // https://vuejs.org/v2/guide/components-edge-cases.html#Forcing-an-Update
          this.$forceUpdate();
        });
    },
    doPrev() {
      this.$router.push({
        name: 'ProgramsApplyStep3',
      });
    },

    /**
     * 투자유치 현황 - 추가 입력 정보 추가
     */
    addInvestInfo() {
      if (this.accInvestInfo.length < this.LIMIT_INVEST_INFO) {
        this.accInvestInfo.push(new AccInvestInfoDto({}));
      }
    },
    /**
     * 투자유치 현황 - 추가 입력 정보 삭제
     */
    deleteInvestInfo(index) {
      if (index < this.LIMIT_INVEST_INFO) {
        this.accInvestInfo.splice(index, 1);
      }
    },
  },
};
</script>
